import React from 'react';
import Typography from '@mui/material/Typography';
import { formatMoney } from '../helpers';

const labelMapper = {
  directCastDebt: 'AL DÍA E IMPAGOS <30 DÍAS', //
  indirectCastDebt: 'INDIRECTA IMPAGOS >= 3 AÑOS', //
  comercialDebt: 'CRÉDITOS COMERCIALES', //
  consumerCreditDebt: 'CRÉDITOS DE CONSUMO', //
  contingentCreditDebt: 'CRÉDITOS CONTINGENTES', //
  mortgageCreditDebt: 'CRÉDITOS PARA VIVIENDA', //
  directExpired3Months3YearsDebt: 'IMPAGOS 180 DÍAS Y 3 AÑOS', //
  directValidDebt: 'AL DÍA E IMPAGOS <30 DÍAS', //
  indirectExpiredDebt: 'INDIRECTA IMPAGOS 30 DÍAS Y 3 AÑOS', //
  indirectValidDebt: 'INDIRECTA IMPAGOS <30 DÍAS', //
  financialDebt: 'OPERACIONES FINANCIERAS', //
  leasingDebt: 'CRÉDITOS LEASING AL DÍA', //
  moroseDebt: 'IMPAGOS 30 Y 90 DÍAS', //
  moroseLeasingDebt: 'CRÉDITOS LEASING IMPAGO', //
  operationCPactDebt: 'INSTRUM. DEUDAS ADQUIRIDOS', //
  directExpiredDebt: 'IMPAGOS 90 Y 180 DÍAS', //
  currentCreditLine: 'LINEA CRÉDITO DISPONIBLE', //
  comercialExpiredAmountDebt: 'DEUDA COM. VENCIDA MEX',
  comercialValidAmountDebt: 'DEUDA COM. VIGENTE MEX',
  consumeCreditNumberCreditors: 'NRO. ENTIDADES CRED.CONSUMO',
  numberConsumeCreditEntities: 'NRO. ENTIDADES.CRED.COM ER.', //
};
const CurrentBehaviorDetailsColumns = (availableOptions) => [
  {
    field: 'label',
    headerName: 'Item',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <Typography>{labelMapper[row.label]}</Typography>
    ),
  },
  {
    field: 'firstValue',
    headerName: availableOptions?.[0].label,
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography>${formatMoney(row.firstValue)}</Typography>
    ),
  },
  {
    field: 'secondValue',
    headerName: availableOptions?.[1].label,
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography>${formatMoney(row.secondValue)}</Typography>
    ),
  },
  {
    field: 'thirdValue',
    headerName: availableOptions?.[2].label,
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography>${formatMoney(row.thirdValue)}</Typography>
    ),
  },
  {
    field: 'fourthValue',
    headerName: availableOptions?.[3].label,
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: ({ row }) => (
      <Typography>${formatMoney(row.fourthValue)}</Typography>
    ),
  },
];

export default CurrentBehaviorDetailsColumns;
